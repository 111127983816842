import Api, {ApiResponse} from "../../../../utils/api";
import {ShopData} from "../../Interfaces";

export async function getInferResponse(): Promise<ShopData> {
    let result = await Api.get(`shop`);
    return result.data;
}

export async function putInferResponse(item: ShopData): Promise<ApiResponse<ShopData>> {
    let result = await Api.put(`shop`, item);
    return result;
}

export async function postImages(item: FormData): Promise<ApiResponse<FormData>> {
    // console.log(item);
    let result = await Api.postWithFormData(`shop/image`, item);
    let value = result as ApiResponse<FormData>;
    return value;
}

export async function deleteImages(url:string) : Promise<ApiResponse<string>> {
    let result = await Api.delete(`shop/image/${url}`);
    return result.data;
}