import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import icon from "../../assets/img/Exclamation.png";


interface NotFoundType {
    text:string
}
const NotFound = ({text}:NotFoundType) => {
    return(
        <div className="font-medium tracking-[-3%] flex flex-col items-center justify-center notFound  min-h-[570px]">
            {/* <ExclamationCircleIcon className="w-[100px]"/> */}
            <div className="w-[130px] borderIconGray-9px h-[130px] rounded-full flex flex-col items-center justify-center mb-[16px]">
                {/*<FontAwesomeIcon icon="exclamation" className="text-[60px] textLightGray"/>*/}
                <img src={icon}/>
            </div>
            <h3 className="font-bold text-[18px] textLightGray">{text}</h3>
        </div>
    );
};

export default NotFound;