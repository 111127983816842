import { IMenu } from "./HeaderList";
import Footer from "./Footer";

export interface ContentLayoutProps {
    title : string,
    list : IMenu[],
    sideMenu: JSX.Element | null ,
    children : JSX.Element,
}


const ContentLayout = (props : ContentLayoutProps) => {
    return(
        <>
            <div className="contentLayout">
                <div className={"w-full overflow-auto h-[100vh] section"}>
                    <div className="contentLayoutSection">
                        {props.children}
                    </div>
                    <Footer/>
                </div>
            </div>
        </>
    )
}

export default ContentLayout;