import Layout from "@/layout/Layout";
import ContentLayout from "@/layout/ContentLayout";
import {NotFound, TopRefresh} from "@/components";
import cellImg from "@/assets/img/구매하기버튼.jpg";
import cellImg2 from "@/assets/img/정기구매버튼.jpg";
import React from "react";

const CommonList =() =>{
    return(
        <Layout loading={false}>
            <ContentLayout list={[]} title="" sideMenu={<></>}
            >
                <TopRefresh refreshBtn={false} refreshFn={()=>{}}>
                    <div className="customerSupportDatail">
                        <NotFound text={"상품을 준비중입니다."}/>
                    </div>
                </TopRefresh>
            </ContentLayout>
        </Layout>
    )
}
export default CommonList;