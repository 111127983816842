import Navbar from "./Navbar";
import React, {useEffect, useState} from "react";
import {LoadMore} from "../pages/LoadMore/LoadMore";
import UseRendingInfer from "@/pages/Shop/Hooks/UseRendingInfer";
import {useRecoilState} from "recoil";
import {ShopCash, ShopCode, Weekday, Weekend} from "@/recoil/UserAtoms";
import SockContainer from "@/SockContainer";
import SignalR from "@/SignalR";
import AdminRouter from "@/AdminRouter";

const Layout = (props: {
    children: React.ReactNode,
    loading: boolean
}) => {
    const {list} = UseRendingInfer();
    const [shopCodeByRecoil, setShopCodeByRecoil] = useRecoilState<number>(ShopCode);
    const [shopCash, setShopCash] = useRecoilState<number>(ShopCash);
    const [weekday,setWeekday] = useRecoilState<string[]>(Weekday);
    const [weekend,setWeekend] = useRecoilState<string[]>(Weekend);
    let isComponentsMount = false;
    useEffect(() => {
        isComponentsMount = true;
        if (isComponentsMount) {
            if (list.shopCode !== 0 && list.shopCode !== undefined && list.shopCode !== null) localStorage.setItem("shopCode", String(list.shopCode));
            setShopCodeByRecoil(list.shopCode);
            setShopCash(list.cash);
            setWeekday([list.weekdayStartTime, list.weekdayEndTime]);
            setWeekend([list.weekendStartTime,list.weekendEndTime]);
            return () => {
                isComponentsMount = false;
            }
        }
    }, [list]);
    return (
        <>
            <div className="bgLayout w-full">
                <div className="flex w-full mx-auto">
                    <Navbar list={list}/>
                    <main className="w-full h-full flex-1 relative ml-[200px] overflow-hidden h-[100vh]">
                        {props.children}
                    </main>
                </div>
            </div>
            {/*<SockContainer />*/}
            {/*<SignalR/>*/}
            {
                props.loading ?
                    <LoadMore isLoading={props.loading}/> :
                    <></>
            }
        </>
    );
};

export default Layout;