import Layout from "@/layout/Layout";
import ContentLayout from "@/layout/ContentLayout";
import {TopRefresh} from "@/components";
import CellingList from "@/pages/MainContents/Templete/CellingList";
import mainImg from "../assets/img/메인배너.jpg";

const MainPage = () => {
    return (
        <Layout loading={false}>
            <ContentLayout list={[]} title="" sideMenu={<></>}>
                <TopRefresh refreshBtn={false} refreshFn={() => {}}>
                    <div className="customerSupport customerSupportCommon listSectionTop  mt-[-27px]">
                        <div className={"mainPageSection"}>
                            <img src={mainImg} className={""}/>
                            <h1 className={"mt-[20px] font-bold text-[35px]"}>인기 상품</h1>
                            <div className={"reservationWithNotice"}>
                                <CellingList />
                            </div>
                        </div>
                    </div>
                </TopRefresh>
            </ContentLayout>
        </Layout>
    )
}
export default MainPage;