import {loadTossPayments} from '@tosspayments/payment-sdk'
import axios from "axios";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useState} from "react";
import Api, {ApiResponse} from "../../../utils/api";
import {Reservation} from "@/pages/AtBat/Interfaces";
import {cashReceiptsType, responsePaymentType} from "@/pages/Payment/Payment.Point";
export interface PaymentResponseType {
    orderId: string,
    paymentKey: string,
    amount: number
}

export interface PaymentType {
    amount: number,
    orderId: string,
    customerName: string,
    payment: string
}
export interface ResponseType {
    orderId: string,
    orderName: string,
    amount:number,
    customerName : string,
    paymentType : string,
    orderDate : string
    // 주문번호 주문한 내역(상품이름) 결제금액 결제방법 결제시간 결제자
}
// export interface DepositDataType  {
//     "AccountHolder" :  string,  		//예금주
//     "OrderName" : string,
//     "DepositorName" : string, 		//입금자 명
//     "DepositorBank" : string, 		//입금 은행
//     "DepositorBankNum" : string, 	//입금 계좌번호
//     "IsCashReceipts" : boolean,		//현금영수증 여부
//     "CashReceiptNum" : string, 		//현금 영수증 번호
//     "Amount" : number
// }
const PaymentService = () => {
    const clientKey = 'test_ck_D5GePWvyJnrK0W0k6q8gLzN97Eoq';
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const postPaymentDataWithDeposit = async (item: cashReceiptsType) => {
        let result = await Api.post<responsePaymentType>("payment/nobank", item);
        let value = result as ApiResponse<responsePaymentType>;
        return value;
    }
    const pointPaymentByToss = async ({amount, orderId, customerName, payment}: PaymentType) => {
        searchParams.set("paymentType",payment);
        const tossPayments = await loadTossPayments(clientKey);
        let location = window.location.origin;
        if (payment === "deposit") {
            tossPayments.requestPayment("가상계좌", { // 결제 수단 파라미터
                // 결제 정보 파라미터
                amount: amount,
                orderId: orderId,
                orderName: customerName,
                customerName: customerName,
                successUrl: `${location}/shop/success`,
                failUrl: `${location}/shop/fail`,
                validHours: 24,
                cashReceipt: {
                    type: '소득공제',
                },
            })
                .catch(function (error) {
                    if (error.code === 'USER_CANCEL') {
                        // 결제 고객이 결제창을 닫았을 때 에러 처리
                    } else if (error.code === 'INVALID_CARD_COMPANY') {
                        // 유효하지 않은 카드 코드에 대한 에러 처리
                    }
                })

        } else if (payment === "creditCard") {
            tossPayments.requestPayment("카드", { // 결제 수단 파라미터
                // 결제 정보 파라미터
                amount: amount,
                orderId: orderId,
                orderName: customerName,
                customerName: customerName,
                successUrl: `${location}/shop/success?${searchParams}`,
                failUrl: `${location}/shop/fail`,
                validHours: 24,
                cashReceipt: {
                    type: '소득공제',
                },
            })
                .catch(function (error) {
                    if (error.code === 'USER_CANCEL') {
                        // 결제 고객이 결제창을 닫았을 때 에러 처리
                    } else if (error.code === 'INVALID_CARD_COMPANY') {
                        // 유효하지 않은 카드 코드에 대한 에러 처리
                    }
                })
        } else if (payment === "bankTransfer") {
            tossPayments.requestPayment("계좌이체", { // 결제 수단 파라미터
                // 결제 정보 파라미터
                amount: amount,
                orderId: orderId,
                orderName: customerName,
                customerName: customerName,
                successUrl: `${location}/shop/success?${searchParams}`,
                failUrl: `${location}/shop/fail`,
                validHours: 24,
                cashReceipt: {
                    type: '소득공제',
                },
            })
                .catch(function (error) {
                    if (error.code === 'USER_CANCEL') {
                        // 결제 고객이 결제창을 닫았을 때 에러 처리
                    } else if (error.code === 'INVALID_CARD_COMPANY') {
                        // 유효하지 않은 카드 코드에 대한 에러 처리
                    }
                })
        }
    }
    const pointPaymentByTossTest = async ({amount, orderId, customerName, payment}: PaymentType) => {

        const tossPayments = await loadTossPayments(clientKey);
        let location = window.location.origin;
        if (payment === "deposit") {
            tossPayments.requestPayment("가상계좌", { // 결제 수단 파라미터
                // 결제 정보 파라미터
                amount: amount,
                orderId: orderId,
                orderName: '포인트 충전',
                customerName: customerName,
                successUrl: `${location}/payment/test/success?${searchParams}`,
                failUrl: `${location}/payment/test/fail`,
                validHours: 24,
                cashReceipt: {
                    type: '소득공제',
                },
            })
                .catch(function (error) {
                    if (error.code === 'USER_CANCEL') {
                        // 결제 고객이 결제창을 닫았을 때 에러 처리
                    } else if (error.code === 'INVALID_CARD_COMPANY') {
                        // 유효하지 않은 카드 코드에 대한 에러 처리
                    }
                })

        } else if (payment === "creditCard") {
            tossPayments.requestPayment("카드", { // 결제 수단 파라미터
                // 결제 정보 파라미터
                amount: amount,
                orderId: orderId,
                orderName: '포인트 충전',
                customerName: customerName,
                successUrl: `${location}/payment/paymentTest/success`,
                failUrl: `${location}/payment/paymentTest/fail`,
                validHours: 24,
                cashReceipt: {
                    type: '소득공제',
                },
            })
                .catch(function (error) {
                    if (error.code === 'USER_CANCEL') {
                        // 결제 고객이 결제창을 닫았을 때 에러 처리
                    } else if (error.code === 'INVALID_CARD_COMPANY') {
                        // 유효하지 않은 카드 코드에 대한 에러 처리
                    }
                })
        } else if (payment === "bankTransfer") {
            tossPayments.requestPayment("계좌이체", { // 결제 수단 파라미터
                // 결제 정보 파라미터
                amount: amount,
                orderId: orderId,
                orderName: '포인트 충전',
                customerName: customerName,
                successUrl: `${location}/payment/test/success`,
                failUrl: `${location}/payment/test/fail`,
                validHours: 24,
                cashReceipt: {
                    type: '소득공제',
                },
            })
                .catch(function (error) {
                    if (error.code === 'USER_CANCEL') {
                        // 결제 고객이 결제창을 닫았을 때 에러 처리
                    } else if (error.code === 'INVALID_CARD_COMPANY') {
                        // 유효하지 않은 카드 코드에 대한 에러 처리
                    }
                })
        }
    }
    const responsePaymentData = async (request: PaymentResponseType) => {
        let result = await Api.post<responsePaymentType>("payment/point", request);
        let value = result as ApiResponse<responsePaymentType>;
        return value;
    }
    return {pointPaymentByToss, responsePaymentData,pointPaymentByTossTest,postPaymentDataWithDeposit};
}
export default PaymentService;