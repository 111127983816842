const TopBtn = () => {
    const clickOnTop = () => {
        const section = document.querySelector(".section");
        if (section === undefined || section === null) return;
        section.scrollTo({
            top: 0,
            behavior: "smooth"

        })
    }
    return (
        <>
            <button type="button" onClick={() => clickOnTop()}>TOP</button>
        </>
    )
}

export default TopBtn;