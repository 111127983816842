import React from 'react';
import RefreshBtn from './RefreshBtn';
import TopBtn from './TopBtn';

interface TopRefreshType {
    children :React.ReactNode,
    refreshBtn : boolean,
    refreshFn : () => void
}
const TopRefresh = ({children,refreshBtn,refreshFn} : TopRefreshType) => {
   
    return(
        <div className={"w-full h-full listHeight"}>
            <div className="topRefreshWrap">
                <div className='btnWrap'>
                    <TopBtn/>
                    <div className={`refreshWrap ${!(refreshBtn) ? "hidden" : ""}`}>
                        <RefreshBtn refreshBtn={refreshBtn} refreshFn={()=>{refreshFn()}}/>
                    </div>
                </div>
            </div>
            {children}
        </div>
    )
}

export default TopRefresh;