import Layout from "@/layout/Layout";
import ContentLayout from "@/layout/ContentLayout";
import {Button, Input, Select, TopRefresh} from "@/components";
import React from "react";
import {useNavigate} from "react-router-dom";
import cellImg from "../../assets/img/구매하기버튼.jpg";
import cellImg2 from "../../assets/img/정기구매버튼.jpg";
import PaymentService from "@/pages/Payment/Services/Point.payment";
import {guid} from "@/utils/methods/Guid";
import AutomaticPayment from "@/pages/Payment/Services/Automatic.payment";

const ShopPage = () => {
    const {pointPaymentByToss} = PaymentService();
    const {cardPaymentByToss} = AutomaticPayment();
    const navigate = useNavigate();
    const graph = "borderBotEaeaea h-[79px] flex items-center";
    const label = "w-[198px] pl-[33px] bgGray_fa h-[100%] flex items-center text-[14px] $Text_black";
    const content = "flex-1 pl-[24px] pr-[83px]";
    return (
        <Layout loading={false}>
            <ContentLayout list={[]} title="" sideMenu={<></>}
            >
                <TopRefresh refreshBtn={false} refreshFn={() => {
                }}>
                    <div className={"borderTopGray"}>
                        <div className={graph}>
                            <div className={label}>
                                이름
                            </div>
                            <div className={content}>
                                <div className={"w-[200px]"}>
                                    <Input id={""} name={""} placeholder={""} size={'sm'} onchange={(e) => {
                                        // setCashReceiptsConfig(CashReceiptsType.depositorName, e.target.value)
                                    }}/>
                                </div>
                            </div>
                        </div>
                        <div className={graph}>
                            <div className={label}>
                                연락처
                            </div>
                            <div className={content}>
                                <div className={"w-[200px]"}>
                                    <Input id={""} name={""} placeholder={""} size={'sm'} onchange={(e) => {
                                        // setCashReceiptsConfig(CashReceiptsType.depositorName, e.target.value)
                                    }}/>
                                </div>
                            </div>
                        </div>
                        <div className={graph}>
                            <div className={label}>
                                주소
                            </div>
                            <div className={content}>
                                <div className={"w-[450px]"}>
                                    <Input id={""} name={""} placeholder={""} size={'sm'} onchange={(e) => {
                                        // setCashReceiptsConfig(CashReceiptsType.depositorName, e.target.value)
                                    }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex justify-center mt-[44px] gap-[20px] mb-[55px]">
                        <div className={'cursor-pointer'} onClick={() => {
                            const orderId = guid();
                            // navigate('/shop');
                            pointPaymentByToss({amount: 20000, customerName: "콜라보 상품", orderId: orderId, payment: "creditCard"})

                        }}>
                            <img src={cellImg}/>
                        </div>
                        <div className={'cursor-pointer'} onClick={() => {
                            // navigate('/shop');
                            const orderId = guid();
                            cardPaymentByToss({amount: 20000, orderId: orderId, minimumAmount:100000})
                        }}>
                            <img src={cellImg2}/>
                        </div>
                    </div>
                </TopRefresh>
            </ContentLayout>
        </Layout>
    )
}
export default ShopPage;