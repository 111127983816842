import Layout from "@/layout/Layout";
import ContentLayout from "@/layout/ContentLayout";
import {Button, TopRefresh} from "@/components";
import React from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {SetHeaderMenu} from "@/pages/Payment/Services/SetHeaderMenu";

const ShopResponsePageError = () => {
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const menuList = SetHeaderMenu();
    const navigate = useNavigate();
    const message = searchParams.get("message");
    return (
        <Layout loading={false}>
            <ContentLayout list={[]} title="결제" sideMenu={<></>}
            >
                <TopRefresh refreshBtn={false} refreshFn={() => {
                }}>
                    <div className="payment">
                        <div className={"paymentHeader"}>
                            <h2>결제승인에 <span className={"textRed2"}>실패</span>했습니다.</h2>
                            <div>결제를 실패했습니다. 실패 사유를 확인 후 다시 시도해 주세요.</div>
                        </div>
                        <div className={"paymentHistorySection"}>
                            <div className={"paymentGraph"}>
                                <div className={"paymentGraphItem"}>
                                    <p>실패 사유</p>
                                    <p>
                                        {
                                            message === null ? "에러 메세지를 불러올 수 없습니다. 본사 고객센터에 문의해주세요."
                                                : message
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={"w-[354px]"}>
                            <Button text="확인" onClick={() => {
                                navigate('/shop')
                            }} size={"xxl"}/>
                        </div>
                    </div>
                </TopRefresh>
            </ContentLayout>
        </Layout>
    );
}

export default ShopResponsePageError;