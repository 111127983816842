import {useEffect, useState} from "react";
import {ShopData} from "../Interfaces";
import {BadRequestError, ForbiddenError} from "../../../utils/api";
import {getInferResponse} from "../Services/Information/Information";

export default function UseRendingInfer() {
    const [isSuccess, setIsSuccess] = useState(false);
    const [list, setList] = useState<ShopData>({
        address: "",
        contacts: "",
        dayOff: "",
        cash: 0,
        mileage: 0,
        shopCode: 0,
        id: "",
        images: [],
        machines: [],
        name: "",
        ownerId: "",
        parkingGuidance: "",
        phone: "",
        roomNum: "",
        so: "",
        weekdayEndTime: "",
        weekdayStartTime: "",
        shopBranch: "",
        weekendEndTime: "",
        weekendStartTime: "",
        zipCode: ""

    });

    const getApi = async () => {
        let value = await getInferResponse();
        let ListData: ShopData = value;
        return {ListData};
    }

    const fetchData = async () => {
        try {
            const api = getApi();
            let apiAsync = (await api);
            let isValid = apiAsync.ListData !== undefined && apiAsync.ListData !== null;
            if(isValid) {
                setList(apiAsync.ListData);
            }
            return () => {
                setIsSuccess(true);
            }
        } catch(error) {
            // console.log(error);
            if(error instanceof TypeError) {
                console.error(error.message);
                // 타입에러 확인용 분기
                return;
            }
            if(error instanceof BadRequestError) {
                console.error(error.message);
                // 서치시 잘못된 요청 (검색결과가 없을때) 이면 값을 받아와서 레이아웃에 적용시키기
                return;
            }
            if(error instanceof ForbiddenError) {
                console.error(error.message);
                //잘못된 url로 접속시 404페이지를 보여주고 메인 페이지나 전 페이지로 가도록 유도하기
                return;
            }
            return () => {
                setIsSuccess(true);
            }
        }
    };
    useEffect(()=>{
        let isComponentMounted = true;
        if(isComponentMounted) {
            fetchData()
        }
        return () => {
            isComponentMounted = false;
        }
    },[]);
    return {list, isSuccess,fetchData};

}
