import Api, {ApiResponse, BadRequestError, UnauthorizedError} from '../utils/api';
import Cookies from 'universal-cookie';

export interface LoginReq {
    id : string,
    pwd :string
}
export interface TokenData {
    token: string,
    refreshToken: string,
    created: Date,
    expires: Date,
    userId: string,
    code: number
}

export interface IProfile {
    id : string,
    name : string,
    role : Role
}

export const Roles = {
    'NONE' : '',
    'ADMIN' : 'admin',
    'STAFF' : 'staff',
}

export const enum Role {
    none,
    admin,
    staff
} 

let cookies = new Cookies();
//전역으로 라이브러리 호출한 후에
let now = new Date();
let afterTime = new Date();

export class AuthService {
    user = "serial";
    current_user: TokenData | null;

    cookieHost : string = '';
    
    constructor(user:TokenData|null = null , host:string) {

        this.current_user = user;
        if (this.cookieHost !== host) {
            let firstDotIdx = host.split('.');
            const cookieHost = `${firstDotIdx[firstDotIdx.length-2]}.${firstDotIdx[firstDotIdx.length-1]}`
            this.cookieHost = `.${cookieHost}`;
            localStorage.setItem('host', cookieHost);
        }
    }

    getHost() {
        return Api.getHost();
    }

    async login(loginReq: LoginReq): Promise<ApiResponse<TokenData>> {

        let result = await Api.post<TokenData>("auth/user", loginReq);
        let value = result as ApiResponse<TokenData>;
        if (value.data !== null) this.setCurrentUser(value.data);
        return value;
    }

    validateToken(token: TokenData | undefined): boolean {
        let isValidate = false;
        if (token !== undefined && token.token !== "" && token.refreshToken !== "") {
            isValidate = true
        }

        return isValidate
    }

    checkToken(): boolean {
        // 토큰이 정상이라고 판단했을때 실행 되는 함수
        try {
            if (this.current_user !== null && this.needRefresh(this.current_user) === false) return true;
            this.refresh().then(
                (token: TokenData) => {
                    const isValidateToken = this.validateToken(token)
                    // 받은 토큰이 검증에 실패
                    if (isValidateToken) {
                        // 토큰 갱신 성공
                        this.setCurrentUser(token);
                        return true;
                    } else {
                        if (this.current_user === undefined) return false
                        const isCurrentValidateToken = this.validateToken(this.current_user!)
                        if (isCurrentValidateToken) return true;
                        else return false;
                    }
                }, (error) => {
                    if(error instanceof UnauthorizedError) {
                        // console.log(error,"UnauthorizedError");
                        return false;
                    }
                    // if(error instanceof BadRequestError) {
                    //     console.log(error,"BadRequestError");
                    //     //TODO 두가지의 경우가 있음
                    //     //1.
                    // }
                    // console.log('http Error', error)
                }
            );
        } catch (e) {
            // console.log(e);
        }
        return false;
    }

    mapRole(reqRole: string): Role {
        let roleResult: Role = Role.none;
        switch (reqRole) {
            case "Admin" :
                roleResult = Role.admin;
                break;
            case "Staff" :
                roleResult = Role.staff;
                break;
            default:
                roleResult = Role.none;
        }

        return roleResult;
    }


    async getRole() : Promise<IProfile> {
        let result =  await Api.get<IProfile>(`staff/role`);
        let userRole = result.data;

        // 문제 1: 현위치에서는 토큰을 리코일에서 받을수 없다 (즉 인자에서 해결)
        // (로그인에서) 토큰이라는 인자를 받을거고 타입은 스트링인거
        // 문제 1-1 : ApiHelper는 현재 토큰을 받을 준비가 안되있다.
        // 문제 2: 저장도 이곳에서 할수 없다. 즉 롤관련 인터페이스데로 리턴이 되야함. 
        // 문제 3: 이 함수에서 리턴이 됐을때 우리는 인터페이스로 리턴이 되길 기대하지만. 실제로는 문자열로 반환될 가능이 있다. 이럴경우엔 밖에서 형변환
        return userRole;
    }

    private async refresh(): Promise<TokenData> {
        const defaultToken: TokenData = {
            code: -1,
            created: new Date(0, 0, 0, 0, 0, 0,),
            expires: new Date(0, 0, 0, 0, 0, 0,),
            refreshToken: "",
            token: "",
            userId: ""
        }

        const user = this.getCurrentUser();
        const refreshToken = user?.refreshToken ?? '';
        const token = user?.token ?? '';
        if (token === undefined || token === '' || refreshToken === '' || user?.expires === new Date(0, 0, 0, 0, 0, 0,)) return defaultToken;
        let result = await Api.post<TokenData>("auth/user/refresh", {"RefreshToken": refreshToken});
        if (result === null || result.code !== "SI000") return defaultToken;
        return result.data;
    }

    //기존에 있었던 로그인에서 넣었던스토리지를 페이지를 확인할때 토큰이 유효하다면 넣는걸로하기

    remove() {
        if(this.getCookies()) this.removeCookies();
        localStorage.removeItem("userRole");
        localStorage.removeItem("userId");
    }
    setCurrentUser(user: TokenData) {
        this.current_user = user;
        Api.setToken(user.token);
        Api.setRefresh(user.refreshToken);
        let data = JSON.stringify(user);
        // console.log(data,"data in cookie")
        this.setCookies(data);
    }
    //쿠키에 값을 저장하는 함수
    // sohong.cokee.io => sohong(서브도메인) cokee.io (메인도메인) [*.cokee.io]
    setCookies(token:string) {
        const branch = window.location.hostname;
        afterTime.setDate(now.getDate() + 30);
        this.removeCookies();
        cookies.set(this.user, token, {domain: this.cookieHost, path: "/", expires: afterTime, sameSite: "lax"});
        cookies.set("branch",branch,{domain: this.cookieHost, path: "/", expires: afterTime, sameSite: "lax"})

    }
    //쿠키에서 값을 받아오는 함수
    getCookies() {
        return cookies.get(this.user);
    }
    removeCookies() {
        cookies.remove(this.user, {domain: this.cookieHost, path: "/", expires: afterTime, sameSite: "lax"});
        cookies.remove("branch",{domain: this.cookieHost, path: "/", expires: afterTime, sameSite: "lax"})
    }
    getCurrentUser() : TokenData | null {
        if (this.current_user !== null && this.current_user.token !== "") {
            const userStr:TokenData = this.getCookies();
            if(userStr === undefined) return this.current_user ;
            if(this.current_user.created < userStr.created) this.current_user = userStr;
            return this.current_user;
        } else {
            const userStr = this.getCookies();
            if (userStr === "undefined" || userStr === undefined) return null;
            this.current_user = (userStr !== "") ? (userStr as TokenData) : null;
        }
        return this.current_user;
    }
    needRefresh(user : TokenData) : boolean {
        let now = new Date();
        const expireDate = new Date(user.expires);
        let remainMs = expireDate.getTime() - now.getTime();
        let remainMinutes = remainMs / (1000*60);
        return (remainMinutes <= 15);
    }
    expiredTime(user : TokenData) : boolean {
        let now = new Date();
        const expireDate = new Date(user.expires);
        let remainMs = expireDate.getTime() - now.getTime();
        let remainMinutes = remainMs / (1000*60);
        return (remainMinutes < 0);
    }
    
    isAvailableUser() : boolean {
        try {
            const user = this.getCurrentUser();
            const userStr = this.getCookies();
            if (user === null || user.token === '' || user.refreshToken === '') {
                if (userStr === "undefined" || userStr === undefined) return false;
            }
            // if(this.expiredTime(userStr) && this.expiredTime(user!)) return false;
            return true;
            //TODO 임시로 유효기간 무시 / 향후 복구해야함
        } catch (error) {
            if(error instanceof UnauthorizedError) return false;
            if(error instanceof BadRequestError) return true;
            return false;
        }
    }
    
}

