import {Route, Routes} from "react-router-dom";
import MainPage from "@/pages";
import React, {useEffect, useState} from "react";
import CollaboratePage from "@/pages/CellingList/CollaboratePage";
import CollaborateDetail from "@/pages/CellingList/CollaborateDetail";
import CommonList from "@/pages/CellingList/CommenList";
import ShopPage from "@/pages/CellingList/ShopPage";
import ShopResponsePage from "@/pages/CellingList/ShopResponsePage";
import ShopResponsePageError from "@/pages/CellingList/ShopResponsePage.Error";

const RouterList = () => {
    return (
        <Routes>
            <Route path="/" element={<MainPage/>}/>
            <Route path="/list" element={<CollaboratePage/>}/>
            <Route path="/list/:url" element={<CollaboratePage/>}/>
            <Route path="/list/detail/:url" element={<CollaborateDetail/>}/>
            <Route path="/list/list/detail/:url" element={<CollaborateDetail/>}/>
            <Route path="/daily" element={<CollaboratePage/>}/>
            <Route path="/commonList" element={<CommonList/>}/>
            <Route path="/etcList" element={<CommonList/>}/>
            <Route path="/shop" element={<ShopPage/>}/>
            <Route path="/shop/success" element={<ShopResponsePage/>}/>
            <Route path="/shop/fail" element={<ShopResponsePageError/>}/>
        </Routes>
    );

}
export default RouterList;