import {useEffect, useState} from "react";
import {RefreshIcon} from '@heroicons/react/outline';

interface RefreshType {
    refreshBtn: boolean,
    refreshFn: () => void
}


const RefreshBtn = ({refreshBtn, refreshFn}: RefreshType) => {
    const [time, setTime] = useState(120);

    useEffect(() => {
        if (!refreshBtn) return;
        const countDown = setInterval(() => {
            if (time > 0) {
                setTime(time - 1)
            } else if (time <= 0) {
                reloadWindow();
            }
        }, 1000);
        return () => clearInterval(countDown);
    }, [time, refreshBtn]);
    const reloadWindow = () => {
        setTime(120);
        refreshFn();
    }
    return(
        <div>
            <div className='refreshMsg'>{time} 초 후 새로고침 됩니다. 타석정보 또는 시간이 다른 경우 새로고침을 선택해주세요.<span>&#9650;</span></div>
            <button type="button" className={`refresh`} onClick={() => reloadWindow()}><RefreshIcon/></button>
        </div>
    )
}

export default RefreshBtn;