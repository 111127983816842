import Api, {ApiResponse, BadRequestError} from '@/utils/api';
import {loadTossPayments} from '@tosspayments/payment-sdk'
import {useSearchParams} from "react-router-dom";
import {autoBillingAuthType, autoPaymentType, requestBillingAuthType} from "@/pages/Payment/Interfaces";

const AutomaticPayment = () => {
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const clientKey = 'test_ck_D5GePWvyJnrK0W0k6q8gLzN97Eoq';

    const cardPaymentByToss = async ({orderId, amount, minimumAmount}: autoPaymentType) => {
        const tossPayments = await loadTossPayments(clientKey);
        let location = window.location.origin;
        searchParams.set("amount", `${amount}`);
        searchParams.set("minimumAmount", `${minimumAmount}`);
        tossPayments.requestBillingAuth('카드', { // 결제 수단 파라미터
            // 결제 정보 파라미터
            customerKey: orderId,
            successUrl: `${location}/shop/success?${searchParams}`,
            failUrl: `${location}/shop/fail`,

        })
            .catch(function (error) {
                if (error.code === 'USER_CANCEL') {
                    // 결제 고객이 결제창을 닫았을 때 에러 처리
                    console.log(error);
                } else if (error.code === 'INVALID_CARD_COMPANY') {
                    // 유효하지 않은 카드 코드에 대한 에러 처리
                    console.log(error);
                } else if (error.code === 'INVALID_CARD_EXPIRATION') {
                    if (error.message.include("유효기간")) {

                    }
                }
            })
    }
    const cardPaymentByTossTest = async ({orderId, amount, minimumAmount}: autoPaymentType) => {
        const tossPayments = await loadTossPayments(clientKey);
        let location = window.location.origin;
        searchParams.set("amount", `${amount}`);
        searchParams.set("minimumAmount", `${minimumAmount}`);
        // debugger;
        tossPayments.requestBillingAuth('카드', { // 결제 수단 파라미터
            // 결제 정보 파라미터
            customerKey: orderId,
            successUrl: `${location}/payment/autoTest/success?${searchParams}`,
            failUrl: `${location}/payment/autoTest/fail`,

        })
            .catch(function (error) {
                if (error.code === 'USER_CANCEL') {
                    // 결제 고객이 결제창을 닫았을 때 에러 처리
                    console.log(error);
                } else if (error.code === 'INVALID_CARD_COMPANY') {
                    // 유효하지 않은 카드 코드에 대한 에러 처리
                    console.log(error);
                } else if (error.code === 'INVALID_CARD_EXPIRATION') {
                    if (error.message.include("유효기간")) {

                    }
                }
            })
    }
    const requestBillingAuth = async (request: requestBillingAuthType) => {
        const result = await Api.post<autoBillingAuthType>("payment/automatic", request);
        let value = result as ApiResponse<autoBillingAuthType>;
        return value;
    }
    return {cardPaymentByToss, requestBillingAuth,cardPaymentByTossTest};
}
export default AutomaticPayment;