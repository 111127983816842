import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {responsePaymentType} from "@/pages/Payment/Payment.Point";
import PaymentService from "@/pages/Payment/Services/Point.payment";
import {BadRequestError} from "@/utils/api";

const UseResponsePayment = () => {
    const [searchParams, _] = useSearchParams(window.location.search);
    const {responsePaymentData} = PaymentService();
    const navigate = useNavigate();
    const [isSending, setIsSending] = useState(false);
    const [responsePayment, setResponsePayment] = useState<responsePaymentType | null>(null);
    const responseData = async () => {
        try {
            if (isSending) return;
            let orderId = searchParams.get("orderId");
            let paymentKey = searchParams.get("paymentKey");
            let amount = searchParams.get("amount");
            if (orderId !== null && paymentKey !== null && amount !== null) {
                const response = await responsePaymentData({
                    orderId: orderId,
                    paymentKey: paymentKey,
                    amount: Number(amount)
                });
                if (response.data !== null && response.data !== undefined) {
                    setIsSending(true);
                    setResponsePayment(response.data);
                    return;
                }
                return;
            } else {
                window.alert("결제 내역 정보를 불러올 수 없습니다.");
                return navigate(`/payment/fail?${searchParams}`);
            }
        } catch (error) {
            if (error instanceof BadRequestError) {
                const newParams = new URLSearchParams();
                newParams.set("message",error.message)
                // window.alert(`${error.message}`);
                return navigate(`/payment/fail?${newParams}`);
            }
            //TODO 400 에러중 처리 실패시 에러 리스트를 받아 케이스에 맞게 에러를 내보내는 로직 추가 필요
        }
    }
    useEffect(() => {
        responseData();
    }, [])
    return {responsePayment,isSending};
}
export default UseResponsePayment;