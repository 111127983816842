import React, { useState } from "react";

interface ButtonType {
    text : string,
    onClick? : (e:any) => void,
    variant? : string, // default(hover), focus, disabled
    size? : string,
    disabled?: boolean,
    icon? : JSX.Element,
    buttonType? : string ,
    value? : string,
    isActive? : boolean,
    style? : string
}
const ButtonComponent = ({text , onClick ,variant = 'default', size ='md' , buttonType = "button" , style, disabled , icon , value , isActive=false} : ButtonType) => {

    return(
        <>
            <button 
                className={`btn w-full ${variant} ${size} ${disabled ? 'disabled' : ''} ${style} ${isActive ? "active": ""}` }
                onClick={(e)=>{
                    if(onClick===undefined) throw new Error();
                    onClick(e);
                }}
                // onChange={()=>Change}
                disabled={disabled}
                type="button"
                value={value}
                // 임시로 데이터가 넘어가지 않게 넣어둠 
            >
                {icon}
                {text}
            </button>
        </>
    )
};
export default ButtonComponent;
