import {useNavigate} from "react-router-dom";
import Layout from "@/layout/Layout";
import ContentLayout from "@/layout/ContentLayout";
import {Button, TopRefresh} from "@/components";
import React from "react";
import {mockList} from "@/pages/MainContents/Templete/CellingList";


const CollaborateDetail = () => {
    const path = window.location.pathname;
    const index = path.lastIndexOf("/");
    const urlNum = path.substring(index + 1);
    const navigate = useNavigate();
    const item = mockList.find((value) => {
        if (value.url === urlNum) return value;
    })
    return (
        <Layout loading={false}>
            <ContentLayout list={[]} title="" sideMenu={<></>}
            >
                <TopRefresh refreshBtn={false} refreshFn={() => {
                }}>
                    <div className="customerSupportDatail">
                        <div className={"flex gap-[30px] items-center"}>
                            <img src={item!.img}/>
                            <div>
                                <div className="font-bold text-[35px]">
                                    {
                                        item!.name
                                    }
                                </div>
                                <div className={"mb-[60px]"}>
                                    {
                                        item!.contents
                                    }
                                </div>
                                <div className={"flex gap-[20px]"}>
                                    <div className="w-[350px] font-bold text-[20px]">
                                        <h2 className={"w-[150px] mb-[20px]"}>20,000 원</h2>
                                        <Button text="구매" size="xxl" onClick={() => navigate('/shop')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"mt-[200px]"}>
                            <img className={"block w-full"} src={item!.detail}/>
                        </div>
                    </div>
                    <div className="w-full flex justify-center mt-[44px] mb-[55px]">
                        <div className="w-[300px]">
                            <Button text="목록" size="xxl" onClick={() => navigate(-1)} />
                        </div>
                    </div>
                </TopRefresh>
            </ContentLayout>
        </Layout>
    )
}
export default CollaborateDetail;