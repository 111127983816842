import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface LoadProps {
    isLoading: boolean
}

export const LoadMore = ({isLoading}: LoadProps) => {
    return (
        <div className={`${isLoading ? "on" : "off"} loading`}>
            <div className="loadingSvg">
                <FontAwesomeIcon icon="spinner" spin/>
            </div>
        </div>
    );
};