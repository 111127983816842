import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { faChartBar } from "@fortawesome/free-regular-svg-icons";
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import {faMoneyCheck} from "@fortawesome/free-solid-svg-icons";
import {faMoneyCheckAlt} from "@fortawesome/free-solid-svg-icons";

library.add(faUser , faCalendarAlt , faCog , faSpinner , faSlidersH, faCamera , faMapMarkerAlt , faExclamation,faMoneyCheck,faMoneyCheckAlt);

