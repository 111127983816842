import CellingListItem from "@/pages/MainContents/Components/CellingListItem";
import img1 from "@/assets/img/썸네일1.jpg";
import img2 from "@/assets/img/썸네일2.jpg";
import img3 from "@/assets/img/썸네일3.jpg";
import img4 from "@/assets/img/썸네일4.jpg";
import detail1 from "@/assets/img/상세1.jpg";
import detail2 from "@/assets/img/상세2.jpg";
import detail3 from "@/assets/img/상세3.jpg";
import detail4 from "@/assets/img/상세4.jpg";
export interface mockListItemType {
    name: string,
    contents: string,
    img: string,
    url: string,
    detail:string
}
export const mockList: mockListItemType[] = [
    {
        name: "진로 두꺼비 분홍",
        contents: "진로 두꺼비 패키지 아이템",
        img: img1,
        url:"1",
        detail:detail1
    },
    {
        name: "진로 두꺼비 파랑",
        contents: "진로 두꺼비 패키지 아이템",
        img: img2,
        url:"2",
        detail:detail2,
    },
    {
        name: "곰표",
        contents: "곰표 패키지 아이템",
        img: img3,
        url:"3",
        detail:detail3
    },
    {
        name: "대박운 흑토끼",
        contents: "2023흑토끼 패키지 아이템",
        img: img4,
        url:"4",
        detail:detail4
    }
]
const CellingList = () => {
    return (
        <>
            {
                mockList.map((item) => {
                    return (
                        <CellingListItem props={item} key={item.name}/>
                    )
                })
            }
        </>
    )
}
export default CellingList;