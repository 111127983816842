import {useNavigate} from "react-router-dom";
import {mockListItemType} from "@/pages/MainContents/Templete/CellingList";
interface listItemType {
    props:mockListItemType,
}
const CellingListItem =({props}:listItemType) => {
    const navigate = useNavigate();
    return(
        <div className={"bg-white w-full borderReservation rounded-[5px] p-[20px] cursor-pointer"} onClick={()=>{
            return navigate(`list/detail/${props.url}`);
        }}>
            <img src={props.img}/>
            <div className={"mt-[10px] font-bold text"}>
                {props.name}
            </div>
        </div>
    )
}
export default CellingListItem;