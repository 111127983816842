const Footer = () => {
    let FooterUlClass = "";
    let FooterLiClass = "";
    return (
        <footer>
            <div>
                <ul className={FooterUlClass}>
                    <li className={FooterLiClass}>
                        <a href="#">TMS이용약관</a>
                        <span className="">|</span>
                        </li>
                    <li>
                        <a href="#">개인정보처리방침</a>
                    </li>
                </ul>
            </div>
            <div className="mt-[9px]">
                <ul className={FooterUlClass}>
                    <li className={FooterLiClass}>
                        대표 : 최재호
                        <span className="">|</span>
                    </li>
                    <li>
                        주소 : (06052)서울특별시 강남구 논현로 134길 10 6층(메종마루빌딩) (주) 더스윙골프
                    </li>
                </ul>
            </div>
            <div className="">
                <ul className={FooterUlClass}>
                    <li className={FooterLiClass}>
                        대표전화 : 010-4358-7447
                        <span className="">|</span>
                    </li>
                    {/*<li>*/}
                    {/*    팩스 : 02-1234-1234*/}
                    {/*    <span className="">|</span>*/}
                    {/*</li>*/}
                    <li>
                        사업자등록번호 : 403-87-01586
                    </li>
                </ul>
            </div>
            <div className="">
                <ul className={FooterUlClass}>
                    <li className={FooterLiClass}>
                        Copyright ⓒ TheSwing All Rights Reserved.*
                    </li>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;