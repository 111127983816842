import React, { ChangeEventHandler } from "react";

interface InputType {
    id : string,
    name : string,
    value? : string,
    type? : string,
    placeholder : string,
    onchange?: ChangeEventHandler<HTMLInputElement> | undefined,
    size? : string,
    variant? : string,
    disabled?: boolean,
    errorMassage? : string
    icon? : JSX.Element,
    onSubmit? : (e:any)=>void,
    readOnly?:boolean
}
const InputComponent = ({id , name , value , type = "text" , onSubmit , placeholder = "" , onchange,readOnly,size = "sm", variant = 'default' , disabled , errorMassage, icon} : InputType) => {

    return(
        <div className="inputWraps flex w-full relative">
            {icon}
            <input
                id={id}
                name={name}
                defaultValue={value}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                onChange={onchange}
                onSubmit={onSubmit}
                readOnly={readOnly}
                className={`w-full outline-none input ${size} ${variant} ${disabled ? 'disabled' : ''} ${icon !== undefined ? "pl-[37px]" : ""}`}
            />
            <span className={`error-massage + ${variant === "error" ? "" : ""}`}>{errorMassage}</span>
        </div>
    )
};

export default InputComponent;