import Layout from "@/layout/Layout";
import ContentLayout from "@/layout/ContentLayout";
import {Button, TopRefresh} from "@/components";
import React from "react";
import {SetHeaderMenu} from "@/pages/Payment/Services/SetHeaderMenu";
import UseResponsePayment from "@/pages/Payment/Hooks/UseResponsePayment";
import {useNavigate} from "react-router-dom";

const ShopResponsePage =() => {
    const {responsePayment, isSending} = UseResponsePayment();
    const navigate = useNavigate();
    return (
        <Layout loading={false}>
            <ContentLayout list={[]} title="결제" sideMenu={<></>}
            >
                <TopRefresh refreshBtn={false} refreshFn={() => {
                }}>
                    <div className="payment">
                        <div className={"paymentHeader"}>
                            <h2>결제가 <span>완료</span>되었습니다.</h2>
                            {
                                responsePayment !== null && responsePayment.orderType === "DepositWithoutBankbook" ?
                                    <div>무통장 입금인 경우 아래 계좌 정보로 입금해 주시면 결제 완료처리가 됩니다.</div> : <></>
                            }

                        </div>
                        {
                            isSending ?
                                responsePayment === null ? <></> :
                                    <div className={"paymentHistorySection"}>
                                        <div className={"paymentGraph"}>
                                            <div className={"paymentGraphItem"}>
                                                <p>결제수단</p>
                                                <p>
                                                    {
                                                        responsePayment.orderType
                                                    }
                                                </p>
                                            </div>
                                            {
                                                // responsePayment.OrderType 영어로 확인 후 변경
                                                responsePayment.orderType === "Card" ?
                                                    <div className={"paymentGraphItem"}>
                                                        <p>카드번호</p>
                                                        <p>
                                                            {responsePayment.cardNum}
                                                            {/*TODO 데이터 형이 바뀌면 수정*/}
                                                        </p>
                                                    </div>
                                                    : <></>
                                            }
                                            {
                                                responsePayment.orderType === "DepositWithoutBankbook" ?
                                                    <>
                                                        <div className={"paymentGraphItem"}>
                                                            <p>입금자명</p>
                                                            <p>
                                                                {responsePayment.depositorName}
                                                                {/*TODO 데이터 형이 바뀌면 수정*/}
                                                            </p>
                                                        </div>
                                                        <div className={"paymentGraphItem"}>
                                                            <p>입금계좌</p>
                                                            <p>
                                                                {responsePayment.depositorBank} / {responsePayment.depositorBankNum} (예금주 : {responsePayment.accountHolder})
                                                                기업은행 / 000-0000-0000-00 (예금주 : 플레이필드)
                                                                {/*TODO 데이터 형이 바뀌면 수정*/}
                                                            </p>
                                                        </div>
                                                    </>
                                                    : <></>
                                            }
                                            {

                                            }
                                            <div className={"paymentGraphItem"}>
                                                <p>
                                                    결제 금액
                                                </p>
                                                <p>
                                                    <span className={"textGreen"}>
                                                    {
                                                        responsePayment.amount
                                                    }
                                                    </span>
                                                    원
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                : <>결제 처리중입니다. 잠시만 기다려주세요.</>
                        }
                        <div className={"w-[354px]"}>
                            <Button text="확인" onClick={()=>{navigate('/payment/point')}} size={"xxl"}/>
                        </div>
                    </div>
                </TopRefresh>
            </ContentLayout>
        </Layout>

    )
}
export default ShopResponsePage;


