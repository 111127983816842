import UseRendingInfer from "@/pages/Shop/Hooks/UseRendingInfer";
import {Link, NavLink, useLocation} from "react-router-dom";
import Logo3 from '../assets/img/Logo3.svg';
import {NavLogout, NavUser} from "../components";
import {NavType} from "@/components/NavUser/NavUserComponent";

export default function Navbar({list}:NavType) {
    let wrapperClass = "NavGnbComponent";
    const currentLocation = useLocation();
    const role = localStorage.getItem("userRole");
    if(role === undefined) console.log(role,"Error");
    return(
        <div className="Navbar" >
            <h1 className="logo"><Link to="/"><img src={Logo3} className="w-[220px]" alt={""}/></Link>
            </h1>
            <nav className="gnb">
                <ul>
                    <li className={wrapperClass}>
                        <NavLink className={(currentLocation.pathname.startsWith('/list/')) ? "active" : ""}
                                 to="/list">콜라보 상품</NavLink>
                    </li>
                    <li className={wrapperClass}>
                        <NavLink className={(currentLocation.pathname.startsWith('/reservation/')) ? "active" : ""}
                                 to="/commonList">일반 상품</NavLink>
                    </li>
                    <li className={wrapperClass}>
                        <NavLink className={(currentLocation.pathname.startsWith('/payment/')) ? "active" : ""}
                                 to="/etcList">기타 용품</NavLink>
                    </li>
                </ul>
            </nav>
            {/*<NavUser list={list}/>*/}
          
            {/*<NavLogout/>*/}
        </div>
    );
}