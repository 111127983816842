import React from "react";
import "./utils/FontAwesome";
import "../src/assets/css/style.css";
import "../src/assets/css/index.css";
import {RecoilRoot, useRecoilState, useRecoilValue} from "recoil";
import RouterList from "@/Router";
import AdminRouter from "./AdminRouter";
import {IProfile} from "@/services/Auth.services";
import {ShopProfileState} from "@/recoil/UserAtoms";
import {BrowserRouter} from "react-router-dom";

// if(role === undefined) console.log(role,"Error");
{/* TODO : 모든페이지에 loadable 적용 https://loadable-components.com/ */}
function App() {
    return (
        <RecoilRoot>
            <div className="App">
                <BrowserRouter>
                    <RouterList/>
                </BrowserRouter>
            </div>
        </RecoilRoot>
    );
};

export default App;
